import Axios, { CancelTokenSource } from "axios";
import { History } from "history";
import { inject, injectable } from "inversify";
import {
  action,
  computed,
  flow,
  IReactionDisposer,
  observable,
  reaction,
  runInAction,
  when,
} from "mobx";
import * as moment from "moment";
import qs from "qs";
import { Subject } from "rxjs";
import { INativeAppService } from "src/services/NativeAppService/interfaces";

import { bugsnagClient } from "../../../../bugsnag";
import { notEmpty } from "../../../../helpers";
import { IDatetime } from "../../../../helpers/Datetime/interfaces";
import {
  ApiServiceSymbol,
  CoreApiServiceSymbol,
  DatetimeSymbol,
  HistorySymbol,
  I18nServiceSymbol,
  NativeAppServiceSymbol,
  SettingsStoreSymbol,
  UiStoreSymbol,
  UserStoreSymbol,
} from "../../../../inversify/symbols";
import {
  AcademyServiceTypes,
  IAcademyServiceModel,
  MotorcycleLessonType,
  ProductType,
  VehicleType,
} from "../../../../models/AcademyServiceModel/interfaces";
import { ErrorMessage } from "../../../../models/ErrorMessage";
import {
  FormFieldPendingState,
  FormFieldState,
} from "../../../../models/FormFieldState";
import {
  GearType,
  IInstructorModel,
  LanguagesSpoken,
  MultipleGearType,
} from "../../../../models/InstructorModel/interfaces";
import { ILocationModel } from "../../../../models/LocationModel/interfaces";
import { PendingStatus } from "../../../../models/PendingStatus";
import { IUserModel } from "../../../../models/UserModel/interfaces";
import {
  IApiAttendee,
  IApiService,
  ICartTotalPrice,
  ICorePromoCodeInfo,
  KlarnaLayout,
} from "../../../../services/ApiService/interfaces";
import {
  isAxiosError,
  isCoreApiError,
} from "../../../../services/AuthService/interfaces";
import {
  IBooking,
  IClientData,
  ICoreApiService,
} from "../../../../services/CoreApiService/interfaces";
import * as interfaces from "../../../../services/I18nService/interfaces";
import { safeLocalStorage } from "../../../../stores/LocalStorage";
import {
  ISettingsStore,
  isIntroServiceKey,
  ServiceKey,
} from "../../../../stores/SettingsStore/interfaces";
import { IUiStore } from "../../../../stores/UiStore/interfaces";
import { isString } from "../../../../stores/UserStore";
import { IUserStore } from "../../../../stores/UserStore/interfaces";
import { ISavedInternalOrder } from "../../../klarna/CheckoutScreen/interfaces";
import { CheckoutScreenModel } from "../../../klarna/CheckoutScreen/model";
import { IClassMeta, IDatePickerOption } from "../../../shared/DatePicker";
import {
  ICityOption,
  IInstructorOption,
  IInstructorSelectOption,
  IRegionOption,
  IServiceOption,
} from "../../../shared/forms/booking/interfaces";
import { calcServiceOptionType } from "../../../shared/forms/booking/ServiceListOption";
import { ITimePickerOpt } from "../../../shared/forms/TimePickerExtended";
import {
  ANY_EN_INSTRUCTORS_OPT,
  ANY_SV_INSTRUCTORS_OPT,
  dict,
  WIDGET_FORM_DATA_KEY,
} from "../../../widget/DemoScreen/DemoForm/DemoFormModel";
import { Attendee } from "./Attendee";
import { AttendeesList } from "./AttendeesList";
import { DiscountHelper } from "./DiscountHelper";
import {
  ERROR_CODE,
  IAttendee,
  IGiftcardsWithAmount,
  IFormStateAttendee,
  IIntervalWithMeta,
  IIntervalWithWorkload,
  IOrderFormMountOptions,
  IOrderFormStateSaved,
  IOrderModel,
  ErrorMessageCode,
} from "./interfaces";
import { RequestCancellerModel } from "./RequestCancellerModel";
import { lazyInject } from "@/inversify/container";
import {
  II18nService,
  Language,
} from "../../../../services/I18nService/interfaces";

@injectable()
export class OrderModel implements IOrderModel {
  @lazyInject(I18nServiceSymbol)
  private readonly i18nService!: II18nService;
  booking: IBooking = {} as IBooking;
  @observable
  public readonly loadingTotalPriceStatus = new PendingStatus();

  @observable
  public giftcardAmount: number = 0;
  public giftCardsSum: { name: string; amount: number }[] = [];

  @computed
  public get dateOptions(): ReadonlyArray<IDatePickerOption<IClassMeta>> {
    return this.getDateOptions();
  }
  @computed
  public get timeOptions(): ITimePickerOpt[] {
    const bookableSlotNumber = this.bookableSlotNumber;
    const selectedDate = this.selectedDate.value;
    if (!selectedDate) {
      return [];
    }
    const now = Date.now();
    const dateTime = moment(selectedDate)
      .clone()
      .startOf("day")
      .toDate()
      .getTime();
    return this.availableInstructorsSlots
      .filter((slot) => {
        const { dates, availableQty } = slot;
        if (!slot.isClass) {
          const d = new Date(dates.from);
          const halfHourFromNow = d.setTime(d.getTime() + -30 * 60000);
          return halfHourFromNow > now && availableQty >= bookableSlotNumber;
        }
        if (slot.isClass) {
          const d = new Date(dates.to);
          const twoHourFromCourseEnd = d.setTime(d.getTime() + 2 * 3600000);

          return (
            twoHourFromCourseEnd > now && availableQty >= bookableSlotNumber
          );
        }
      })
      .filter((slot) => {
        const { dates } = slot;
        return (
          moment(dates.from).clone().startOf("day").toDate().getTime() ===
          dateTime
        );
      })
      .slice()
      .sort((a, b) => a.dates.from.valueOf() - b.dates.from.valueOf())
      .map((slot) => {
        return {
          availableQty: slot.availableQty,
          date: slot.dates.from,
          endTime: slot.dates.to,
          entityId: slot.entityId,
          isClass: slot.isClass,
          seatsInSlot: slot.seatsInSlot,
        };
      });
  }

  @computed
  get allRegionOptions(): IRegionOption[] {
    const options: Array<IRegionOption> = this.settingsStore.regions
      .slice()
      .map(this.toCityOption.bind(this));
    return options;
  }

  @computed
  public get regionOptions(): IRegionOption[] {
    const { user } = this.userStore;

    const serviceRegions = this.settingsStore.services
      .slice()
      .filter((ser) => ser.isVisible)
      .filter((ser) => ser.vehicleType === this.selectedWheeledVehicle.value)
      .map((ser) => ser.regions)
      .flat();
    const serviceRegionsByVechicle = [...new Set(serviceRegions)];

    let options = this.settingsStore.regions
      .slice()
      .filter((r) => r.isVisible)
      .filter((r) => serviceRegionsByVechicle.includes(r.id))
      .map((location) => {
        return {
          label: location.name[this.i18n.currentLanguage],
          model: location,
          value: location.id,
        };
      });

    if (user) {
      const { profile } = user;
      options = options
        .filter((option) =>
          profile.availableRegions.find((x) => x === option.value)
        )
        .sort((a, b) => {
          try {
            return a.label.localeCompare(b.label, this.i18n.currentLanguage, {
              numeric: true,
            });
          } catch (e) {
            return 0;
          }
        });
    }

    return options;
  }

  @computed
  get allCityOptions(): ICityOption[] {
    const options: Array<ICityOption> = this.settingsStore.locations
      .slice()
      .map(this.toCityOption.bind(this));
    return options;
  }

  private readonly toCityOption = (location) => {
    return {
      label: location.name[this.i18n.currentLanguage],
      model: location,
      value: location.id,
    };
  };

  @computed
  public get cityOptions(): ICityOption[] {
    const { user } = this.userStore;
    const serviceLocations = this.settingsStore.services
      .slice()
      .filter((ser) => ser.isVisible)
      .filter((ser) => ser.vehicleType === this.selectedWheeledVehicle.value)
      .map((ser) => ser.ownLocations)
      .flat();

    const serviceLocationsByVechicle = [...new Set(serviceLocations)];

    const options: Array<{
      model: ILocationModel;
      label: string;
      value: number;
    }> = this.settingsStore.locations
      .slice()
      .filter((loc) =>
        (this.selectedRegion?.value?.model.locationsIds || []).includes(loc.id)
      )
      .filter((loc) => serviceLocationsByVechicle.includes(loc.id))
      .filter((loc) => loc.isVisible)
      .sort((a, b) => {
        return a.name[this.i18n.currentLanguage]
          .replace(",", "")
          .localeCompare(
            b.name[this.i18n.currentLanguage].replace(",", ""),
            this.i18n.currentLanguage,
            {
              numeric: true,
            }
          );
      })
      .map(this.toCityOption.bind(this));

    // Jason said remove this filtering 10.03.2023. I'm not sure. Maybe it will help you in the future.

    // if (user) {
    //   const { profile, saldoBalance } = user;
    //
    //   if (profile && saldoBalance > 0) {
    //     const { availableLocations } = profile;
    //     options = options.filter((option) =>
    //       availableLocations.find((x) => x === option.value)
    //     );
    //   }
    // }
    return options;
  }
  private toServiceOption = (
    academyService: IAcademyServiceModel
  ): IServiceOption => {
    return {
      isHide: false,
      label: academyService.name[this.i18n.currentLanguage],
      model: academyService,
      type: calcServiceOptionType(academyService),
      value: academyService.id,
      isClass: academyService.isClass,
      productType: academyService.productType,
    };
  };

  @computed
  get allServiceOptions() {
    return this.settingsStore.services.slice().map(this.toServiceOption);
  }
  @computed
  public get serviceOptions(): IServiceOption[] {
    const selectedCity = this.selectedCity.value;
    if (!selectedCity) {
      return [];
    }

    const location = this.settingsStore.locations.find(
      (x) => x.id === selectedCity.value
    );
    if (!location) {
      console.error("Selected location not found in the SettingsStore");
      return [];
    }

    const serviceType = (s: IAcademyServiceModel) => {
      return `${s.meta?.type}-${s.meta?.key}-${s.languageCode}`;
    };

    const hasTestLesson =
      this.userStore.user &&
      this.userStore.user.profile &&
      !!this.userStore.user.profile.testLessonDate;
    const ownLocationMap = new Map<string, IAcademyServiceModel[]>();

    const services = this.settingsStore.services
      .slice()
      .filter((ser) => {
        if (!this.selectedWheeledVehicle.value) {
          return false;
        }
        if (!ser.vehicleType && ser.packageLessonsQty > 0) {
          return true;
        }
        if (ser.vehicleType === this.selectedWheeledVehicle.value) {
          return true;
        }
      })
      .filter((service) => {
        if (service.vehicleType === VehicleType.Moped) {
          if (!service.ownLocations.includes(location.id)) {
            return false;
          }
        }
        if (!service.locations.some((loc) => loc === location.id)) {
          return false;
        }
        if (service.ownLocations.some((loc) => loc === location.id)) {
          const type = serviceType(service);
          ownLocationMap.set(
            type,
            (ownLocationMap.get(type) || []).concat([service])
          );
        }
        return true;
      })
      .filter((ser) => {
        if (ser.languageCode) {
          if (ser.languageCode === this.selectedServiceLanguage) {
            return true;
          }
        } else {
          return true;
        }
      })
      .filter((service) => {
        const type = serviceType(service);
        if (["risk1", "intro"].some((i) => type.includes(i))) {
          const ownLocationsOfType: IAcademyServiceModel[] | undefined =
            ownLocationMap.has(type) ? ownLocationMap.get(type)! : undefined;
          if (ownLocationsOfType) {
            return ownLocationsOfType.includes(service);
          }
        }
        return true;
      })
      .sort((a, b) => {
        if (selectedCity.value) {
          const posA = a.positionInLocation.get(selectedCity.model.regionId);
          const posB = b.positionInLocation.get(selectedCity.model.regionId);

          if (typeof posA === "number" && typeof posB === "number") {
            return posA - posB;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      })
      .map((academyService) => {
        return {
          ...this.toServiceOption(academyService),
          isHide:
            (hasTestLesson && academyService.meta?.key === ServiceKey.Test) ||
            (academyService.isVisible != null && !academyService.isVisible),
        };
      })
      .sort((a, b) => {
        if (
          a.model.positionInLocation.has(
            this.selectedRegion?.value?.value as unknown as number
          ) &&
          b.model.positionInLocation.has(
            this.selectedRegion?.value?.value as unknown as number
          )
        ) {
          const aPriority = a.model.positionInLocation.get(
            this.selectedRegion?.value?.value as unknown as number
          ) as number;
          const bPriority = b.model.positionInLocation.get(
            this.selectedRegion?.value?.value as unknown as number
          ) as number;
          return bPriority < aPriority ? 1 : bPriority > aPriority ? -1 : 0;
        }
        return 0;
      })
      .sort((a, b) => a.type - b.type);

    if (
      !services.find(
        (service) =>
          service.value === this.selectedService.value?.value &&
          service.productType === this.selectedService.value.productType
      )
    ) {
      return [...services, this.selectedService.value!]
        .filter((a) => !!a)
        .sort((a, b) => {
          if (
            a.model.positionInLocation.has(
              this.selectedRegion?.value?.value as unknown as number
            ) &&
            b.model.positionInLocation.has(
              this.selectedRegion?.value?.value as unknown as number
            )
          ) {
            const aPriority = a.model.positionInLocation.get(
              this.selectedRegion?.value?.value as unknown as number
            ) as number;
            const bPriority = b.model.positionInLocation.get(
              this.selectedRegion?.value?.value as unknown as number
            ) as number;
            return bPriority < aPriority ? 1 : bPriority > aPriority ? -1 : 0;
          }
          return 0;
        })
        .sort((a, b) => a.type - b.type);
    }
    return services;
  }

  @computed
  public get serviceOptionsLocations(): IServiceOption[] {
    const selectedCity = this.selectedCity.value;
    if (!selectedCity) {
      return [];
    }

    const location = this.settingsStore.locations.find(
      (x) => x.id === selectedCity.value
    );
    if (!location) {
      console.error("Selected location not found in the SettingsStore");
      return [];
    }

    const serviceType = (s: IAcademyServiceModel) => {
      return `${s.meta?.type}-${s.meta?.key}-${s.languageCode}`;
    };

    const hasTestLesson =
      this.userStore.user &&
      this.userStore.user.profile &&
      !!this.userStore.user.profile.testLessonDate;
    const ownLocationMap = new Map<string, IAcademyServiceModel[]>();

    return this.settingsStore.services
      .slice()
      .filter((service) => {
        if (!service.locations.some((loc) => loc === location.id)) {
          return false;
        }
        if (service.ownLocations.some((loc) => loc === location.id)) {
          const type = serviceType(service);
          ownLocationMap.set(
            type,
            (ownLocationMap.get(type) || []).concat([service])
          );
        }
        return true;
      })
      .filter((ser) => {
        if (!this.selectedWheeledVehicle.value) {
          return false;
        }
        if (!ser.vehicleType && ser.packageLessonsQty > 0) {
          return true;
        }
        if (ser.vehicleType === this.selectedWheeledVehicle.value) {
          return true;
        }
      })
      .filter((ser) => {
        if (ser.languageCode) {
          if (ser.languageCode === this.selectedServiceLanguage) {
            return true;
          }
        } else return true;
      })
      .sort((a, b) => {
        if (selectedCity.value) {
          const posA = a.positionInLocation.get(selectedCity.model.regionId);
          const posB = b.positionInLocation.get(selectedCity.model.regionId);

          if (typeof posA === "number" && typeof posB === "number") {
            return posA - posB;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      })
      .map((academyService) => {
        return {
          ...this.toServiceOption(academyService),
          isHide:
            (hasTestLesson && academyService.meta?.key === ServiceKey.Test) ||
            (academyService.isVisible != null && !academyService.isVisible),
        };
      })
      .sort((a, b) => {
        if (
          a.model.positionInLocation.has(
            this.selectedRegion?.value?.value as unknown as number
          ) &&
          b.model.positionInLocation.has(
            this.selectedRegion?.value?.value as unknown as number
          )
        ) {
          const aPriority = a.model.positionInLocation.get(
            this.selectedRegion?.value?.value as unknown as number
          ) as number;
          const bPriority = b.model.positionInLocation.get(
            this.selectedRegion?.value?.value as unknown as number
          ) as number;
          return bPriority < aPriority ? 1 : bPriority > aPriority ? -1 : 0;
        }
        return 0;
      })
      .sort((a, b) => a.type - b.type);
  }

  @computed
  get isVehicleType() {
    return this.selectedService.value?.model.vehicleType;
  }

  @computed
  get allInstructorOptions() {
    return this.settingsStore.instructors.map((instructor) =>
      this.instructorToOption(instructor)
    );
  }
  @computed
  public get instructorOptions(): IInstructorOption[] {
    //const isAutomaticCar = this.isAutomaticCar.value;
    const selectedService = this.selectedService.value;
    const selectedCity = this.selectedCity.value;

    if (!selectedService || !selectedCity) {
      return [];
    }
    const { availableUnitsIds } = selectedService.model;

    const { availableInstructorsIds } = selectedCity.model;

    return this.settingsStore.instructors
      .filter((instructor) => {
        return availableInstructorsIds
          ? availableInstructorsIds.find((id) => instructor.id === id)
          : true;
      })
      .filter((instructor) => {
        return availableUnitsIds.find((id) => instructor.id === id);
      })
      .filter((instructor) => instructor.isActive)
      .filter((instructor) => {
        if (
          selectedService &&
          selectedService.model.meta?.type !== AcademyServiceTypes.Lesson
        ) {
          return true; // courses and tests available with any transmission
        }
        if (this.selectedVehicleGearType.value) {
          return (
            this.selectedVehicleGearType.value &&
            instructor.instructorVehicleGearTypes?.includes(
              this.selectedVehicleGearType.value
            )
          );
        }
        if (
          this.selectedWheeledVehicle.value === VehicleType.Mc &&
          this.isMCPreSelected
        ) {
          return (
            this.isMCPreSelected &&
            instructor.instructorVehicleGearTypes?.includes(
              this.isMCPreSelected
            )
          );
        }
        if (
          this.selectedWheeledVehicle.value === VehicleType.Car &&
          this.isCarPreSelected
        ) {
          return (
            this.isCarPreSelected &&
            instructor.instructorVehicleGearTypes?.includes(
              this.isCarPreSelected
            )
          );
        }
      })
      .sort((a, b) => {
        const posA = a.positionInLocation.get(selectedCity.value);
        const posB = b.positionInLocation.get(selectedCity.value);

        if (typeof posA === "number" && typeof posB === "number") {
          return posA - posB;
        } else {
          return 0;
        }
      })
      .map((instructor) => this.instructorToOption(instructor));
  }
  @computed
  public get additionalInstructorOptions(): IInstructorSelectOption[] {
    const anyEnglishInstrOpt =
      this.englishSpeakingInstructors.length > 0
        ? {
            ...ANY_EN_INSTRUCTORS_OPT,
            label: this.i18n.i18next
              .t("order.chooseAnyEngInstructor")
              .toString(),
          }
        : undefined;

    const anySwedishInstrOpt =
      this.englishSpeakingInstructors.length > 0
        ? {
            ...ANY_SV_INSTRUCTORS_OPT,
            label: this.i18n.i18next.t("order.chooseAnyInstructor").toString(),
          }
        : undefined;

    return [anySwedishInstrOpt, anyEnglishInstrOpt].filter(notEmpty);
  }

  @computed
  public get options(): IInstructorSelectOption[] {
    if (!this.instructorOptions.length) {
      return [];
    }
    return [
      ...this.additionalInstructorOptions,
      ...this.instructorOptions.map((opt) => {
        const { model, ...rest } = opt;
        return {
          ...rest,
          picture: model.picture || "",
          value: String(model.id),
        };
      }),
    ];
  }

  @computed
  public get englishSpeakingInstructors(): number[] {
    return this.instructorOptions
      .filter((el) =>
        el.model.languagesSpoken.some((lang) => lang === LanguagesSpoken.EN)
      )
      .map((el) => el.model.id);
  }
  @computed
  public get swedishSpeakingInstructors(): number[] {
    return this.instructorOptions
      .filter((el) =>
        el.model.languagesSpoken.some((lang) => lang === LanguagesSpoken.SV)
      )
      .map((el) => el.model.id);
  }

  @computed
  public get instructorIdsList(): number[] {
    if (this.selectedOption.value?.value === dict.svVal) {
      return this.swedishSpeakingInstructors;
    } else if (this.selectedOption.value?.value === dict.enVal) {
      return this.englishSpeakingInstructors;
    } else {
      return [];
    }
  }
  @computed
  public get lessonsLeft(): number {
    const { user } = this.userStore;
    if (!user) {
      return 0;
    }
    return user.saldoBalance;
  }
  @computed
  public get attendeesMode(): boolean {
    const selectedService = this.selectedService.value;
    if (!selectedService || !selectedService.model.meta) {
      return false;
    }
    return isIntroServiceKey(selectedService.model.meta.key);
  }
  @computed
  public get transmissionMatter(): boolean {
    const selectedService = this.selectedService.value;
    return (
      !!selectedService &&
      selectedService.model.meta?.type === AcademyServiceTypes.Lesson
    );
  }

  @computed
  public get isBundle(): boolean {
    const selectedService = this.selectedService.value;
    return (
      !!selectedService &&
      selectedService.model.productType === ProductType.Bundle
    );
  }

  @computed
  get bookableSlotNumber(): number {
    if (isIntroServiceKey(this.selectedService.value?.model?.meta?.key)) {
      return this.attendees.value.length;
    }
    return 1;
  }

  /**
   * @deprecated
   */
  public get widgetData(): IOrderFormStateSaved | null {
    const widgetRawData: string | null =
      safeLocalStorage.getItem(WIDGET_FORM_DATA_KEY);

    if (!widgetRawData) {
      return null;
    }

    const widgetData = JSON.parse(widgetRawData);

    return {
      attendees: [
        {
          name: this.userStore.user
            ? [
                this.userStore.user.firstName,
                this.userStore.user.lastName,
              ].join(" ")
            : "",
          saved: true,
          ssn: this.userStore.user ? this.userStore.user.ssn : "",
        },
      ],
      vehicleType: widgetData.selectedWheeledVehicle
        ? widgetData.selectedWheeledVehicle
        : VehicleType.Car,
      date: widgetData.selectedDate
        ? new Date(widgetData.selectedDate).getTime()
        : undefined,
      instructorId: widgetData.selectedInstructorId,
      vehicleGearType: widgetData.selectedVehicleGearType,
      mcLessonType: widgetData.selectedMotorcycleLessonType,

      locationId: widgetData.selectedCityId,
      promo: widgetData.promoCode,
      regionId: widgetData.selectedRegionId,
      serviceId: widgetData.selectedServiceId,
      productType: widgetData.selectedProductType,
      time: widgetData.selectedTime
        ? new Date(widgetData.selectedTime).getTime()
        : undefined,
    };
  }

  private get fields(): Array<
    FormFieldState<any> | FormFieldPendingState<any>
  > {
    const fields: Array<FormFieldState<any> | FormFieldPendingState<any>> = [
      this.selectedRegion,
      this.selectedCity,
      this.selectedService,
      this.promoCode,
      this.giftcard,
    ];
    if (this.selectedService.value?.model.productType === ProductType.Service) {
      fields.push(this.selectedDate);
      fields.push(this.selectedTime);
      if (!this.selectedService.value?.isClass) {
        fields.push(this.selectedInstructor);
        fields.push(this.selectedOption);
      }

      if (this.attendeesMode) {
        fields.push(this.attendees);
      }
    }
    return fields;
  }

  @computed
  public get isFormValid(): boolean {
    return !this.fields.some((field) => !field.isValid);
  }
  @computed
  public get isFormValidating(): boolean {
    return this.fields.some((field) => field.validatingStatus.isPending);
  }

  public get isCarPreSelected(): GearType | undefined {
    return (this.selectedWheeledVehicle.value === VehicleType.Car &&
      this.userStore.user?.settings.options.vehicleGearType ===
        GearType.Manual) ||
      this.userStore.user?.settings.options.vehicleGearType ===
        GearType.Automatic
      ? this.userStore.user?.settings.options.vehicleGearType
      : undefined;
  }
  public get isMCPreSelected(): GearType | undefined {
    return this.selectedWheeledVehicle.value === VehicleType.Mc &&
      this.userStore.user?.settings.options.vehicleGearTypeMc
      ? this.userStore.user.settings.options.vehicleGearTypeMc
      : undefined;
  }

  public get currentVehicleGearType(): GearType | undefined {
    if (this.selectedVehicleGearType.value) {
      return this.selectedVehicleGearType.value;
    } else if (this.isMCPreSelected) return this.isMCPreSelected;
    else if (this.isCarPreSelected) return this.isCarPreSelected;
    else if (this.isMCPreSelected && this.isMCPreSelected) return undefined;
    else undefined;
  }

  private get queryParams(): IOrderFormStateSaved | undefined {
    const querySearchString = this.history.location.search.replace("?", "");
    const params = qs.parse(querySearchString);

    const {
      vehicleType,
      locationId,
      instructorId,
      promo,
      isAutomaticCar,
      vehicleGearType,
      mcLessonType,
      serviceId,
      regionId,
      productType,
    } = params;
    const thereAreQuery = Object.keys(params).some((key) => !!params[key]);
    return thereAreQuery
      ? {
          attendees: [],
          date: undefined,
          instructorId: Number(instructorId) || undefined, // fixme
          vehicleGearType: vehicleGearType
            ? vehicleGearType.toString()
            : Boolean(Number(isAutomaticCar))
            ? GearType.Automatic
            : GearType.Manual,
          mcLessonType: mcLessonType?.toString() || undefined,
          locationId: Number(locationId) || undefined, // fixme
          promo: isString(promo) ? promo : undefined,
          regionId: Number(regionId) || undefined, // FIXME!
          serviceId: Number(serviceId) || undefined, // fixme
          time: undefined,
          productType: productType?.toString() || undefined,
          vehicleType: vehicleType ? vehicleType.toString() : VehicleType.Car,
        }
      : undefined;
  }
  public static STATE_KEY = "orderFormState";
  public static saveDataInLocalStore(state: IOrderFormStateSaved) {
    console.debug("saveDataInLocalStore", state);
    safeLocalStorage.setItem(OrderModel.STATE_KEY, JSON.stringify(state));
  }
  public static fetchDataFromLocalStore(): IOrderFormStateSaved | undefined {
    const data = safeLocalStorage.getItem(OrderModel.STATE_KEY);
    let state: IOrderFormStateSaved | undefined;
    if (data) {
      state = JSON.parse(data) as IOrderFormStateSaved;
    } else {
      state = undefined;
    }
    console.debug("fetchDataFromLocalStore", state);

    return state;
  }

  public static clearData(): void {
    safeLocalStorage.removeItem(OrderModel.STATE_KEY);
  }
  public checkoutPending = new PendingStatus();
  public datePickerLoading = new PendingStatus();
  public availableInstructorsSlots = observable.array<IIntervalWithMeta>();
  @observable
  public slotsLeftInClass = 0;
  @observable
  public seatsInSlot = 0;
  @observable
  public seatsLeftInLesson = 0;
  @observable
  public qpRestoreWarning = "";
  @observable
  public mcLessonTypeWarning = "";
  @observable
  public lessonEndTime = new Date();
  @observable
  public errorStatus = new ErrorMessage();
  @observable
  public coreServices?: IAcademyServiceModel[] = undefined;
  @observable
  public studentCity: ICityOption | undefined;
  @observable
  public studentRegion: IRegionOption | undefined;
  @observable
  public selectedServiceLanguage: Language | undefined;

  @computed
  get realLocation() {
    if (
      this.selectedService.value &&
      this.selectedService.value.model?.meta?.type === AcademyServiceTypes.Class
    ) {
      return this.allCityOptions.filter(
        (option) =>
          option.value === this.selectedService.value?.model.ownLocations[0]
      )[0];
    }
    return this.selectedCity.value;
  }

  public selectedCity = new FormFieldState<ICityOption | undefined>(undefined, {
    validator: (value: ICityOption | undefined) => {
      if (!value) {
        return "cityRequired";
      }
    },
  });
  @observable
  public cartTotalPrice!: ICartTotalPrice | null;

  @action
  public setCartTotalPrice(cartTotalPrice: ICartTotalPrice | null) {
    this.cartTotalPrice = cartTotalPrice;
  }

  public selectedRegion = new FormFieldState<IRegionOption | undefined>(
    undefined,
    {
      validator: (value: IRegionOption | undefined) => {
        if (!value) {
          return "regionRequired";
        }
      },
    }
  );
  public selectedMotorcycleLessonType = new FormFieldState<
    MotorcycleLessonType | undefined
  >(undefined, {
    validator: (value: MotorcycleLessonType | undefined) => {
      if (!value) {
        return "LessonTypeRequired";
      }
    },
  });
  public selectedService = new FormFieldState<IServiceOption | undefined>(
    undefined,
    {
      validator: (value: IServiceOption | undefined) => {
        if (!value) {
          return "serviceRequired";
        }
      },
    }
  );
  public selectedInstructor = new FormFieldState<IInstructorOption | undefined>(
    undefined,
    {
      validator: (value: IInstructorOption | undefined) => {
        if (!value) {
          return "instructorRequired";
        }
      },
    }
  );
  public selectedOption = new FormFieldState<
    IInstructorSelectOption | undefined
  >(undefined, {
    validator: (value: IInstructorSelectOption | undefined) => {
      if (!value) {
        return "instructorRequired";
      }
    },
  });
  public selectedDate = new FormFieldState<Date | undefined>(undefined, {
    validator: (value: Date | undefined) => {
      if (!value) {
        return "dateRequired";
      }
    },
  });
  public selectedTime = new FormFieldState<Date | undefined>(undefined, {
    validator: (value: Date | undefined) => {
      if (!value) {
        return "timeRequired";
      }
    },
  });

  public giftcards: string[] = [];

  public giftcard = new FormFieldPendingState<string | undefined>(undefined, {
    validationDelay: (v) => {
      if (!v) {
        return 0;
      }
      return 1800;
    },
    validator: (() => {
      let previousValue: string;
      let previousResult: any;
      return async (value, cancelled) => {
        if (!value) {
          this.setCartTotalPrice(null);
          this.setGiftCardAmount(0);
          return null;
        }
        let priceValidation;
        let directValidation;

        // @todo unblock in case of bundle promo code

        directValidation = this.validateGiftCard({
          attendeesCount: this.attendeesMode ? this.attendees.value.length : 1,
          day: this.selectedDate.value,
          giftcard: value.trim(),
          serviceId: this.selectedService.value
            ? this.selectedService.value.value
            : undefined,
          time: this.selectedTime.value,
        });
        previousResult = Promise.all([priceValidation, directValidation]).then(
          ([a, b]) => b
        );
        return previousResult;
      };
    })(),
  });

  @action
  public applyGiftcard(): void {
    try {
      this.setGifts(this.giftcard.value?.trim() as string);
      const data: ISavedInternalOrder = this.orderData();
      this.requestCartTotalPrice(data);
      this.giftCardsSum.push({
        name: this.giftcard.value as string,
        amount: this.giftcardAmount,
      });
      this.giftcard.value = "";
      this.giftcardAmount = 0;
    } catch (e) {
      throw e;
    }
  }
  @action
  public deleteGiftcard(name: string): void {
    this.giftCardsSum.splice(
      this.giftCardsSum.findIndex((v) => v.name === name),
      1
    );
    this.giftcards.splice(
      this.giftcards.findIndex((v) => v === name),
      1
    );
    const data: ISavedInternalOrder = this.orderData();
    this.requestCartTotalPrice(data);
  }
  private async requestCartTotalPrice(data: ISavedInternalOrder) {
    try {
      runInAction(() => {
        this.loadingTotalPriceStatus.startPending();
      });
      const {
        attendees,
        eventId,
        locationId,
        promocode,
        giftcards,
        sid: oldSid,
        unitId,
        firstLessonStartDateTime,
        productType,
      } = data;
      this.setCartTotalPrice(null);

      const clientId = this.userStore.user!.id;
      if (!data.promocode && !data.giftcards) {
        return;
      }
      const isClass =
        !!this.selectedService.value && this.selectedService.value?.isClass;

      const resp = await this.coreApiService.checkCartPrice(
        {
          attendees,
          clientId,
          eventId,
          firstLessonStartDateTime: new Date(firstLessonStartDateTime),
          isClass,
          klarnaOrderId: oldSid,
          layout: KlarnaLayout.Mobile,
          locationId,
          promocode,
          giftcards,
          unitId,
          productType,
        },
        this.currentVehicleGearType //this.isAutomaticCar.value ? GearType.Automatic : GearType.Manual
      );

      this.setCartTotalPrice(resp);
    } catch (e) {
      (e instanceof Error && e.message) || String(e);
    } finally {
      this.loadingTotalPriceStatus.stopPending();
    }
  }

  public promoCode = new FormFieldPendingState<string | undefined>(undefined, {
    validationDelay: (v) => {
      if (!v) {
        return 0;
      }
      return 1800;
    },
    validator: (() => {
      let previousResult: any;
      return async (value, cancelled) => {
        if (!value) {
          this.setCartTotalPrice(null);
          this.setPromoCodeData(undefined);
          return null;
        }

        let priceValidation;
        let directValidation;

        // @todo unblock in case of bundle promo code
        const data: ISavedInternalOrder = this.orderData();
        priceValidation = this.requestCartTotalPrice(data);

        directValidation = this.validatePromocode({
          attendeesCount: this.attendeesMode ? this.attendees.value.length : 1,
          day: this.selectedDate.value,
          promoCode: value,
          serviceId: this.selectedService.value
            ? this.selectedService.value.value
            : undefined,
          time: this.selectedTime.value,
        });
        previousResult = Promise.all([priceValidation, directValidation]).then(
          ([a, b]) => b
        );
        return previousResult;
      };
    })(),
  });

  public selectedVehicleGearType = new FormFieldState<GearType | undefined>(
    this.userStore.user &&
    this.userStore.user.settings.options.vehicleGearTypeMc
      ? this.userStore.user.settings.options.vehicleGearTypeMc
      : this.userStore.user?.settings.options.vehicleGearType
      ? this.userStore.user.settings.options.vehicleGearType
      : undefined
  );

  public isAutomaticCar = new FormFieldState<boolean | null>(
    this.userStore.user && this.userStore.user.profile
      ? undefined !== this.userStore.user.profile.isAutomaticCar
        ? this.userStore.user.profile.isAutomaticCar
        : null
      : null
  );
  public selectedWheeledVehicle = new FormFieldState<VehicleType | undefined>(
    this.userStore.user &&
    this.userStore.user.settings.options.vehicleGearTypeMc &&
    this.userStore.user.settings.options.vehicleGearType
      ? undefined
      : this.userStore.user?.settings.options.vehicleGearTypeMc
      ? VehicleType.Mc
      : this.userStore.user?.settings.options.vehicleGearType
      ? VehicleType.Car
      : undefined
  );

  @observable
  public promoCodeData: ICorePromoCodeInfo | undefined;
  @observable
  public datePickerDate: Date;
  @observable
  public somethingWentWrong: boolean | undefined;

  @observable
  public promoCodeStatus: boolean | undefined;
  public attendees = new AttendeesList(observable.array(), {
    validator: flow<string | undefined, [IAttendee[]]>(function* (
      this: OrderModel,
      value: IAttendee[]
    ) {
      console.group("AttendeesList validator");
      try {
        value.forEach((attendee) => attendee.requestValidation());
        yield when(() => !value.some((attendee) => attendee.validationPending));
        let attendeeNotSaved = false;
        for (const attendee of value) {
          if (!attendee.saved) {
            attendee.addError("mustBeSaved");
            attendeeNotSaved = true;
          }
        }
        yield this.validateAttendeesCount();
        if (attendeeNotSaved) {
          return "hasNotSavedAttendees";
        }
        if (value.some((attendee) => !attendee.saved)) {
          return "someAttendeesAreNotSaved";
        }
        if (value.some((attendee) => !attendee.isValid)) {
          return "someAttendeesAreNotValid";
        }
      } finally {
        console.groupEnd();
      }
    }).bind(this),
  });

  private orderData(): ISavedInternalOrder {
    const packageTypes = new Set<ServiceKey>([
      ServiceKey.BasPacket,
      ServiceKey.MaxiPacket,
      ServiceKey.MellanPacket,
      ServiceKey.MiniPacket,
    ]);

    const { promoCodeData } = this;
    const selectedServiceModel = this.selectedService.value!.model;
    const calculatedMoneys = promoCodeData
      ? DiscountHelper.calcMoneys(
          promoCodeData,
          selectedServiceModel.priceWithVat,
          selectedServiceModel.price,
          this.attendeesMode ? this.attendees.value.length : 1
        )
      : undefined;

    const selectedDate = this.selectedDate.value!;
    const selectedTime = this.selectedTime.value!;
    const selectedProductId = this.selectedService.value!.value;
    const selectedProductType = this.selectedService.value!.model.productType;

    const priceWithDiscount = calculatedMoneys
      ? calculatedMoneys.priceWithTax
      : selectedServiceModel.priceWithVat;
    const selectedCity = this.selectedCity.value!.value;
    const selectedInstructor = this.selectedInstructor.value?.value;
    const firstLessonStartDateTime = this.datetime
      .fromDayAndTimePart(selectedDate, selectedTime)
      .toDate()
      .getTime();
    const data: ISavedInternalOrder = {
      eventId: selectedProductId,
      productType: selectedProductType,
      firstLessonStartDateTime,
      isPacket:
        !!this.selectedService.value &&
        !!this.selectedService.value?.model.meta?.key &&
        packageTypes.has(this.selectedService.value.model.meta.key),
      locationId:
        this.realLocation?.value ||
        this.selectedService.value?.model?.ownLocations?.[0] ||
        selectedCity,
      price: selectedServiceModel.priceWithVat,
      priceWithDiscount,
      promocode: this.promoCode.value,
      giftcards: this.giftcards,
      unitId: selectedInstructor ? Number(selectedInstructor) : undefined,
    };
    if (this.attendeesMode) {
      data.attendees = this.attendees.value.map((attendee) => {
        return {
          name: attendee.name.value,
          ssn: attendee.ssn.value,
        };
      });
    }
    if (this.booking) {
      data.bookingReservationId = this.booking.id;
    }
    return data;
  }

  public readonly pay = flow<void, []>(function* (this: OrderModel) {
    try {
      console.group("pay");
      this.checkoutPending.startPending();

      this.setSomethingWentWrong(false);
      const data = { ...this.orderData() };

      yield this.tryToPay(data);
    } finally {
      if (this.userStore.user) {
        bugsnagClient.notify(
          {
            message: "order",
            name: `User_${this.userStore.user.id}`,
          },
          { severity: "info" }
        );
      }
      this.checkoutPending.stopPending();
      console.groupEnd();
    }
  }).bind(this);

  public readonly checkout = flow<void, []>(function* (this: OrderModel) {
    try {
      console.group("checkout");
      this.checkoutPending.startPending();

      this.setWholeFormTouched();
      this.setSomethingWentWrong(false);
      yield this.validateWholeForm();
      if (this.isFormValidating) {
        console.debug("form validation in process. Checkout denied");
        throw new Error("form validation in process. Checkout denied");
      }
      if (!this.isFormValid) {
        console.debug("form is not valid. Checkout denied", this);
        throw new Error("form is not valid. Checkout denied");
      } else {
        console.debug("form is valid. Checkout process is started");
      }
      const data = this.orderData();

      const previousOrderData =
        CheckoutScreenModel.fetchInternalOrderFromLocalStore();
      console.debug("Previous order data", previousOrderData);
      if (previousOrderData) {
        const safePadding = 60000; // 1min padding in case of network lags
        console.debug("we have unfinished order", previousOrderData);
        const timeBeforeBookingReleased =
          previousOrderData.timestamp +
          this.settingsStore.countdownDuration * 1000;
        const weHaveEnoughTime: boolean =
          timeBeforeBookingReleased - safePadding > Date.now();

        if (weHaveEnoughTime) {
          console.debug(
            "we have enough time to reuse existing unfinished order",
            previousOrderData
          );
          let isComplete;

          if (previousOrderData.data.sid) {
            try {
              isComplete = yield this.coreApiService.getOrderStatus(
                previousOrderData.data.sid
              );
            } catch (e) {
              isComplete = false;
            }
          }
          console.debug("Previous order completed state", isComplete);
          if (!isComplete) {
            data.bookingId = previousOrderData.data.bookingId;
            data.sid = previousOrderData.data.sid;
          }
        }
        console.groupEnd();
      }

      CheckoutScreenModel.saveInternalOrderInLocalStore(data);
      yield this.tryToOrder(data);

      if (this.uiStore.widgetMode && this.widgetData) {
        safeLocalStorage.removeItem(WIDGET_FORM_DATA_KEY);
      }
    } finally {
      if (this.userStore.user) {
        bugsnagClient.notify(
          {
            message: "order",
            name: `User_${this.userStore.user.id}`,
          },
          { severity: "info" }
        );
      }
      this.checkoutPending.stopPending();
      console.groupEnd();
    }
  }).bind(this);
  public readonly updatingSlotsLeftInClassStatus = new PendingStatus();
  public cancelPrevOrder = flow(function* (this: OrderModel) {
    console.log("Cancel previous order attempt");
    const previousOrderData =
      CheckoutScreenModel.fetchInternalOrderFromLocalStore();
    console.log("Previous Data Check");
    const waitForIsCompleteCheckFinish = new Subject();
    this.requestCancellerModel.addAction(
      waitForIsCompleteCheckFinish.toPromise()
    );
    try {
      if (previousOrderData && previousOrderData.data.sid) {
        const safePadding = 60000; // 1min padding in case of network lags
        console.debug("we have unfinished order", previousOrderData);
        const timeBeforeBookingReleased =
          previousOrderData.timestamp +
          this.settingsStore.countdownDuration * 1000;
        const weHaveEnoughTime: boolean =
          timeBeforeBookingReleased - safePadding > Date.now();

        if (weHaveEnoughTime) {
          const isComplete = yield this.coreApiService.getOrderStatus(
            previousOrderData.data.sid
          );
          if (!isComplete) {
            yield this.coreApiService.releaseReservations(
              previousOrderData.data.sid
            );
            console.log("Is Complete");
            const {
              datePickerDate,
              selectedInstructor,
              selectedService,
              selectedLocation,
              selectedMotorcycleLessonType,
            } = {
              datePickerDate: this.datePickerDate,
              selectedInstructor: this.selectedOption.value,
              selectedLocation: this.selectedCity.value,
              selectedService: this.selectedService.value,
              selectedMotorcycleLessonType:
                this.selectedMotorcycleLessonType.value,
            };

            this.errorStatus.clear();
            yield this.updateSelectableDates(
              datePickerDate,
              !!this.instructorIdsList.length
                ? this.instructorIdsList
                : selectedInstructor && [Number(selectedInstructor.value)],
              selectedService,
              selectedLocation,
              selectedMotorcycleLessonType
            ).catch((err) => console.error(err));
          }
        }
      }
    } finally {
      waitForIsCompleteCheckFinish.next("Is Complete");
      waitForIsCompleteCheckFinish.complete();
      setTimeout(() => {
        OrderModel.clearData();
        CheckoutScreenModel.clearInternalOrderData();
        CheckoutScreenModel.clearKlarnaSnippetData();
      });
    }
  });
  private readonly selectableDatesDisposer: IReactionDisposer;
  private readonly selectableTimesDisposer: IReactionDisposer;
  private formStateDisposer: IReactionDisposer | undefined;
  private readonly promocodeDisposer: IReactionDisposer | undefined;
  private attendeesDisposer: IReactionDisposer | undefined;
  private attendeesValidationDisposer: IReactionDisposer | undefined;
  private promoCodeValidationCancelTokenSource: CancelTokenSource | undefined;
  private restoreForm = flow(function* (this: OrderModel) {
    console.debug("restoreForm");
    const user = this.userStore.user;
    if (!user) {
      throw new Error("User must be authenticated");
    }
    yield user.refreshProfile();
    try {
      const waitForRestore = yield this.requestCancellerModel.waitForRestore();
      console.log("Awaited", waitForRestore);
    } finally {
      this.requestCancellerModel.clearActions();
    }
    let data;
    let filledFromQP = false;
    if (this.uiStore.widgetMode) {
      data = this.widgetData || OrderModel.fetchDataFromLocalStore();
      if (!!this.queryParams) {
        data = this.queryParams;
        filledFromQP = true;
      }
    } else {
      data = OrderModel.fetchDataFromLocalStore();
    }

    let attendeesRestored = false;
    if (data && data.regionId) {
      const restoreResult = yield this.restoreFormFromLocalStore(
        user,
        data,
        filledFromQP
      );
      attendeesRestored = restoreResult.attendeesRestored;
    } else {
      const { settings } = user;

      const { currentLocationId } = settings;
      const { vehicleGearType, vehicleGearTypeMc } = settings.options;
      const isCarWheeledVehicle = vehicleGearType;
      const isMcWheeledVehicle = vehicleGearTypeMc;
      const studentLanguage = this.i18nService.currentLanguage;

      const cityOption = currentLocationId
        ? this.settingsStore.locations.find(
            (loc) => loc.id === currentLocationId
          )
        : undefined;

      const regionOption = cityOption?.regionId
        ? this.settingsStore.regions.find(
            (reg) => reg.id === cityOption.regionId
          )
        : undefined;
      this.studentCity = cityOption ? this.toCityOption(cityOption) : undefined;
      this.studentRegion = regionOption
        ? {
            model: regionOption,
            value: regionOption?.id,
            label: regionOption.name.en,
          }
        : undefined;
      if (studentLanguage) {
        this.selectServiceLanguage(studentLanguage);
      }

      if (
        (isCarWheeledVehicle && isMcWheeledVehicle) ||
        (!isCarWheeledVehicle && !isMcWheeledVehicle)
      ) {
        this.setWheeledVehicle(undefined);
        if (currentLocationId && !cityOption) {
          console.warn(`City  with id "${currentLocationId}" was not found`);
        }
        cityOption &&
          this.selectedCity.setValue({
            model: cityOption,
            value: cityOption.id,
            label: cityOption?.name.en,
          });
        regionOption &&
          this.selectedRegion.setValue({
            model: regionOption,
            value: regionOption?.id,
            label: regionOption.name.en,
          });
      } else {
        isMcWheeledVehicle
          ? this.setWheeledVehicle(VehicleType.Mc)
          : this.setWheeledVehicle(VehicleType.Car);
        if (currentLocationId && !cityOption) {
          console.warn(`City  with id "${currentLocationId}" was not found`);
        }
        cityOption &&
          this.selectedCity.setValue({
            model: cityOption,
            value: cityOption.id,
            label: cityOption?.name.en,
          });
        regionOption &&
          this.selectedRegion.setValue({
            model: regionOption,
            value: regionOption?.id,
            label: regionOption.name.en,
          });
      }
    }
    if (!attendeesRestored) {
      const attendee = this.createAttendee({
        name: `${user.firstName} ${user.lastName}`,
        saved: true,
        ssn: user.ssn,
      });
      this.attendees.addAttendee(attendee);
    }

    yield this.validateWholeForm();
    const formRestoredState = this.formSavedState();
    this.setQpRestoreWarning("");
    OrderModel.saveDataInLocalStore(formRestoredState);
  }).bind(this);

  private readonly restoreFormFromLocalStore = flow(function* (
    this: OrderModel,
    user: IUserModel,
    data: IOrderFormStateSaved,
    isPrefilledFromUrl: boolean // Enna UI /tool
  ) {
    try {
      console.group("restoreFormFromLocalStore");
      const {
        locationId,
        serviceId,
        instructorId,
        date,
        time,
        promo,
        regionId,
        vehicleGearType,
        mcLessonType,
        vehicleType,
        productType,
      } = data;

      const { profile, settings } = user;
      const { currentLocationId } = settings;
      let attendeesRestored = false;
      const studentLanguage = this.i18nService.currentLanguage;

      if (studentLanguage) {
        this.selectServiceLanguage(studentLanguage);
      }

      if (vehicleType) {
        this.setWheeledVehicle(vehicleType as VehicleType);
      }

      const regionOpt = this.regionOptions.find(
        (region) => region.value === regionId
      );

      if (!!regionOpt) {
        this.selectRegion(regionOpt);
        if (locationId) {
          const cityOption = this.cityOptions.find((location) => {
            return location.value === locationId;
          });
          if (cityOption) {
            this.selectCity(cityOption);
          } else {
            if (currentLocationId && isPrefilledFromUrl) {
              const currentCity = this.getLocationLabel(currentLocationId);
              const promotedCity = this.getLocationLabel(locationId);
              this.setQpRestoreWarning(
                this.i18n.i18next.t("warnings.city", {
                  currentCity,
                  promotedCity,
                })
              );
            } else {
              console.warn("City is not found or not available anymore");
            }
            return { attendeesRestored };
          }
        }
      } else {
        return { attendeesRestored };
      }

      //find service using query params

      const serviceOption = serviceId
        ? this.allServiceOptions
            .filter((option) => option.productType === productType)
            .find((option) => option.value === serviceId)
        : undefined;

      if (serviceOption?.model.languageCode) {
        this.selectServiceLanguage(
          serviceOption?.model.languageCode as Language
        );
      }

      if (serviceOption) {
        if (
          serviceOption.model.meta?.key === ServiceKey.Test &&
          !!profile.testLessonDate
        ) {
          this.setQpRestoreWarning(this.i18n.i18next.t("warnings.testLesson"));
          return { attendeesRestored };
        } else {
          this.selectService(serviceOption, true);
        }
      } else {
        return { attendeesRestored };
      }

      if (promo) {
        this.setPromo(promo, true);
      }

      if (
        (settings.options.vehicleGearType &&
          this.selectedWheeledVehicle.value === VehicleType.Car &&
          settings.options.vehicleGearType !== vehicleGearType) ||
        (settings.options.vehicleGearTypeMc &&
          this.selectedWheeledVehicle.value === VehicleType.Mc &&
          settings.options.vehicleGearTypeMc !== vehicleGearType)
      ) {
        this.setQpRestoreWarning(
          this.i18n.i18next.t("warnings.isAutomaticCar", {
            carType: vehicleGearType,
            currentCarType:
              this.selectedWheeledVehicle.value === VehicleType.Car
                ? settings.options.vehicleGearType
                : settings.options.vehicleGearTypeMc,
          })
        );
        this.setVehicleGerType(
          this.selectedWheeledVehicle.value === VehicleType.Car
            ? settings.options.vehicleGearType
            : settings.options.vehicleGearTypeMc
        );
      } else if (vehicleGearType) {
        this.setVehicleGerType(vehicleGearType as GearType);
      }

      if (mcLessonType && this.isMCPreSelected) {
        this.selectMotorcycleLessonType(mcLessonType as MotorcycleLessonType);
      }

      const instructorOption = instructorId
        ? this.instructorOptions.find(
            (option) => Number(option.value) === instructorId
          )
        : undefined;

      if (instructorOption) {
        runInAction(() => {
          this.datePickerDate = new Date(date || Date.now());
          this.selectOption(instructorOption, true);
        });
      } else {
        return { attendeesRestored };
      }

      // wait until instructor options will be restored
      yield when(() => !this.datePickerLoading.isPending);
      if (date) {
        const startOfDay = moment(new Date(date))
          .startOf("day")
          .toDate()
          .getTime();

        const dateOptions = this.getDateOptions(startOfDay);

        if (
          dateOptions.some((option) => option.value.getTime() === startOfDay)
        ) {
          this.selectDate(new Date(date), true);
          if (time) {
            if (
              this.timeOptions.some((option) => option.date.getTime() === time)
            ) {
              this.selectTime(new Date(time), true);
            } else {
              console.warn("Selected time is not longer available");
            }
          }
        } else {
          console.warn("Selected date is not longer available");
        }
      }
      const attendees = data.attendees
        ? data.attendees.map((attendeeRaw) => this.createAttendee(attendeeRaw))
        : [];
      if (attendees.length > 0) {
        attendeesRestored = true;
        attendees.forEach((attendee) => this.attendees.addAttendee(attendee));
      }
      return { attendeesRestored };
    } finally {
      console.groupEnd();
    }
  }).bind(this);

  private readonly validateAttendeesCount = flow(function* (this: OrderModel) {
    yield when(() => !this.updatingSlotsLeftInClassStatus.isPending);
    try {
      console.group("validateAttendeesCount");
      const diff = this.slotsLeftInClass - this.attendees.value.length;
      // if this is a new order (not a reservation) and there are not enough slots left, show error
      if (diff < 0 && !this.booking) {
        const restAttendees = this.attendees.value.slice(diff);
        restAttendees.forEach((attendee) => {
          attendee.addError(ERROR_CODE.ThereAreNoEnoughSlots);
        });
      }
    } finally {
      console.groupEnd();
    }
  }).bind(this);
  constructor(
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(ApiServiceSymbol) private readonly apiService: IApiService,
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService,
    @inject(HistorySymbol) private readonly history: History,
    @inject(DatetimeSymbol) private readonly datetime: IDatetime,
    @inject(I18nServiceSymbol) private readonly i18n: interfaces.II18nService,
    @inject(UserStoreSymbol) private readonly userStore: IUserStore,
    @inject(UiStoreSymbol) private readonly uiStore: IUiStore,
    @inject(NativeAppServiceSymbol)
    private readonly nativeService: INativeAppService,

    @inject(RequestCancellerModel)
    private readonly requestCancellerModel: RequestCancellerModel
  ) {
    const orderData = OrderModel.fetchDataFromLocalStore();
    this.datePickerDate =
      orderData && orderData.date ? new Date(orderData.date) : new Date();

    // Automatically select first time occurrence
    this.selectableTimesDisposer = reaction(
      () => ({
        timeOptions: this.timeOptions,
        bookableSlotNumber: this.bookableSlotNumber,
      }),
      ({ timeOptions }) => {
        const selectableTimeOptions = timeOptions.filter((to) => !to.disabled);

        if (selectableTimeOptions.length === 1) {
          const {
            date,
            entityId,
            isClass,
            endTime,
            availableQty,
            seatsInSlot,
          } = selectableTimeOptions[0];

          this.setSlotsLeftInClass(availableQty);
          if (!isClass) {
            const instructor = this.getInstructorOptionById(entityId);
            this.selectInstructor(instructor);
            this.setSeatsLeftInLesson(availableQty);
            this.setSeatsInSlot(seatsInSlot);
          }

          this.setLessonEndTime(endTime);

          this.selectTime(date);
        }
        if (selectableTimeOptions.length === 0 && this.booking?.isPaid) {
          this.selectTime(undefined);
        }
      }
    );

    this.selectableDatesDisposer = reaction(
      () => ({
        datePickerDate: this.datePickerDate,
        selectedInstructor: this.selectedOption.value,
        selectedLocation: this.selectedCity.value,
        selectedService: this.selectedService.value,
        selectedMotorcycleLessonType: this.selectedMotorcycleLessonType.value,
      }),
      ({
        datePickerDate,
        selectedInstructor,
        selectedService,
        selectedLocation,
        selectedMotorcycleLessonType,
      }) => {
        this.errorStatus.clear();
        this.updateSelectableDates(
          datePickerDate,
          !!this.instructorIdsList.length
            ? this.instructorIdsList
            : selectedInstructor && [Number(selectedInstructor.value)],
          selectedService,
          selectedLocation,
          selectedMotorcycleLessonType
        ).catch((err) => console.error(err));
      }
    );

    this.promocodeDisposer = reaction(
      () => {
        return {
          attendeesCount: this.attendeesMode ? this.attendees.value.length : 1,
          day: this.selectedDate.value,
          serviceId: this.selectedService.value
            ? this.selectedService.value.value
            : undefined,
          time: this.selectedTime.value,
        };
      },
      (data) => {
        console.debug("promocode disposer", data);
        this.promoCode.requestValidation();
      }
    );

    this.attendeesValidationDisposer = reaction(
      () => {
        return {
          attendeesCount: this.attendees.value.length,
          attendeesMode: this.attendeesMode,
          attendeesSaved: this.attendees.value.map(
            (attendee) => attendee.saved
          ),
        };
      },
      ({ attendeesMode }) => {
        console.group("attendeesValidationDisposer");
        try {
          if (attendeesMode) {
            console.debug(
              "attendeesCount or saved status was changed. Check pre-requisites"
            );
            const selectedDate = this.selectedDate.value;
            const selectedTime = this.selectedTime.value;
            const selectedService = this.selectedService.value;
            if (!selectedDate || !selectedTime || !selectedService) {
              console.debug(
                "Required fields don't set. Skip attendees validation"
              );
            } else {
              this.attendees.requestValidation();
            }
          }
        } finally {
          console.groupEnd();
        }
      },
      { delay: 100 }
    );
  }
  public setCurrentStudentLocationRegion() {
    const currentLocationId = this.userStore?.user?.settings.currentLocationId;
    if (currentLocationId) {
      const loc = this.settingsStore.locations.find(
        (loc) => loc.id === currentLocationId
      );
      const reg = this.settingsStore.regions.find(
        (reg) => reg.id === loc?.regionId
      );
      if (loc && reg) {
        this.selectedCity.setValue({
          model: loc,
          value: currentLocationId,
          label: loc?.name.en,
        });
        this.selectedRegion.setValue({
          model: reg,
          value: reg?.id,
          label: reg.name.en,
        });
      }
    }
  }
  private formSavedState = () => {
    return {
      attendees: this.attendees.value.map((attendee) => {
        return {
          name: attendee.name.value,
          saved: attendee.saved,
          ssn: attendee.ssn.value,
        };
      }),
      date: this.selectedDate.value && this.selectedDate.value.getTime(),
      instructorId:
        this.selectedInstructor.value &&
        Number(this.selectedInstructor.value.value),
      /*isAutomaticCar:
        undefined !== this.isAutomaticCar.value
          ? this.isAutomaticCar.value
          : null,*/
      vechicleGearType:
        this.selectedVehicleGearType.value &&
        this.selectedVehicleGearType.value,
      locationId: this.selectedCity.value && this.selectedCity.value.value,
      promo: this.promoCode.value,
      vehicleType:
        this.selectedWheeledVehicle.value && this.selectedWheeledVehicle.value,
      regionId: this.selectedRegion.value && this.selectedRegion.value?.value,
      serviceId: this.selectedService.value && this.selectedService.value.value,
      time: this.selectedTime.value && this.selectedTime.value.getTime(),
    };
  };
  private mountOptions!: IOrderFormMountOptions;

  public async mount(options: IOrderFormMountOptions) {
    this.mountOptions = options || {};
    if (!options?.restoreDisabled) {
      await this.restoreForm();

      this.formStateDisposer = reaction(
        this.formSavedState,
        (state: IOrderFormStateSaved) => {
          this.setQpRestoreWarning("");
          OrderModel.saveDataInLocalStore(state);
        }
      );
    }
  }
  public unmount() {
    this.selectableDatesDisposer();
    if (this.formStateDisposer) {
      this.formStateDisposer();
    }
    if (this.promocodeDisposer) {
      this.promocodeDisposer();
    }
    if (this.attendeesDisposer) {
      this.attendeesDisposer();
    }
  }

  @action
  public setQpRestoreWarning(val: string) {
    this.qpRestoreWarning = val;
  }
  @action
  public setMcLessonTypeWarning(val: string) {
    this.mcLessonTypeWarning = val;
  }
  @action
  public setCoreServices(val: IAcademyServiceModel[]) {
    this.coreServices = val;
  }
  @action
  public selectCity(
    city: ICityOption | undefined,
    setTouched: boolean = false
  ) {
    this.selectedCity.setValue(city);
    if (setTouched) {
      this.selectedCity.setTouched(true);
    }
  }

  @action
  public selectRegion(
    region: IRegionOption | undefined,
    setTouched: boolean = false
  ) {
    this.selectedRegion.setValue(region);
    if (setTouched) {
      this.selectedRegion.setTouched(true);
    }
  }

  @action
  public selectService(
    service: IServiceOption | undefined,
    setTouched: boolean = false
  ) {
    const previousServiceType = this.selectedService.value?.model.productType;
    const newServiceType = service?.model.productType;

    if (newServiceType !== previousServiceType) {
      this.setPromo(undefined, false);
      this.promoCodeData = undefined;
      this.promoCode.requestValidation();
    }

    this.selectedService.setValue(service);
    if (setTouched) {
      this.selectedService.setTouched(true);
    }
  }

  @action
  public selectDate(date: Date | undefined, setTouched: boolean = false) {
    this.selectedDate.setValue(date);
    if (setTouched) {
      this.selectedDate.setTouched(true);
    }
  }
  @action
  public changeDatePickerDate(date: Date) {
    this.datePickerDate = date;
  }

  @action
  public setLessonEndTime(time: Date) {
    this.lessonEndTime = time;
  }

  @action
  public selectTime(time: Date | undefined, setTouched: boolean = false) {
    this.selectedTime.setValue(time);
    if (setTouched) {
      this.selectedTime.setTouched(true);
    }
  }
  @action
  public selectServiceLanguage(
    lng: Language | undefined,
    setTouched: boolean = false
  ) {
    this.selectedServiceLanguage = lng || this.i18nService.currentLanguage;
    if (setTouched) {
      this.selectedDate.setTouched(true);
    }
  }
  @action
  public selectInstructor(
    instructor: IInstructorOption | undefined,
    setTouched: boolean = false
  ) {
    this.selectedInstructor.setValue(instructor);
    if (setTouched) {
      this.selectedInstructor.setTouched(true);
    }
  }
  @action
  public selectOption(
    instructor: IInstructorSelectOption | undefined,
    setTouched: boolean = false
  ) {
    if (!!instructor) {
      const instructorOption = this.instructorOptions.find(
        (x) => x.model.id === Number(instructor.value)
      );
      this.selectInstructor(instructorOption);
    }
    this.selectedOption.setValue(instructor);
    if (setTouched) {
      this.selectedOption.setTouched(true);
    }
  }

  @action
  public setWheeledVehicle(val: VehicleType | undefined, setTouched = false) {
    this.selectedWheeledVehicle.setValue(val);
    if (setTouched) {
      this.selectedWheeledVehicle.setTouched(true);
    }
  }

  @action
  public setVehicleGerType(isVehicleType: GearType | undefined) {
    this.selectedVehicleGearType.setValue(isVehicleType);
  }
  @action
  public selectMotorcycleLessonType(
    val: MotorcycleLessonType | undefined,
    setTouched: boolean = false
  ) {
    this.selectedMotorcycleLessonType.setValue(val);
    if (setTouched) {
      this.selectedMotorcycleLessonType.setTouched(true);
    }
  }
  @action
  public setIsAutomaticCar(isAutomatic: boolean, setTouched = false) {
    this.isAutomaticCar.setValue(isAutomatic);
    if (setTouched) {
      this.isAutomaticCar.setTouched(true);
    }
    const selectedInstructor = this.selectedInstructor.value;
    if (
      this.transmissionMatter &&
      selectedInstructor &&
      !this.instructorOptions.find(
        (option) => option.value === selectedInstructor.value
      )
    ) {
      this.selectOption(undefined);
      this.selectInstructor(undefined); // instructor is not available
    }
  }

  @action
  public setPromo(val?: string, setTouched = false) {
    this.promoCode.setValue(val);
    if (setTouched) {
      this.promoCode.setTouched(true);
    }
  }
  @action
  public setGiftCardAmount(amount: number) {
    this.giftcardAmount = amount;
  }
  @action
  public setGift(val?: string, setTouched = false) {
    this.giftcard.setValue(val);
    if (setTouched) {
      this.giftcard.setTouched(true);
    }
  }

  @action
  public setGifts(val: string) {
    this.giftcards.push(val);
  }

  @action
  public setSlotsLeftInClass(val: number) {
    this.slotsLeftInClass = val;
  }

  @action
  public setSeatsInSlot(val: number) {
    this.seatsInSlot = val;
  }

  @action
  public setSeatsLeftInLesson(val: number) {
    this.seatsLeftInLesson = val;
  }

  // set attendees for reservation
  @action
  public setAttendees(attendees: IApiAttendee[]) {
    if (attendees.length) {
      attendees.forEach((attendee) => {
        const createdAttendee = this.createAttendee({
          name: `${attendee.name}`,
          saved: true,
          ssn: attendee.ssn,
        });
        this.attendees.addAttendee(createdAttendee);
      });
    }
  }

  public getInstructorOptionById(id: number): IInstructorOption | undefined {
    const instructor = this.settingsStore.instructors.find(
      (currentInstructor) => id === currentInstructor.id
    );

    return !!instructor ? this.instructorToOption(instructor) : undefined;
  }

  @action
  public setSomethingWentWrong(val: boolean | undefined) {
    this.somethingWentWrong = val;
  }

  private getDateOptions(startingDate = Date.now()): Array<{
    model: { disabled: boolean };
    value: Date;
  }> {
    const dateSlots: {
      [dateStartTs: number]: {
        availableQty: number;
        disabled: boolean;
      };
    } = {};

    this.availableInstructorsSlots
      .filter((slot) => {
        const { dates } = slot;
        if (!slot.isClass) {
          return dates.from.getTime() - startingDate > 30 * 60 * 1000;
        }
        if (slot.isClass) {
          const d = new Date(dates.to);
          const twoHourFromCourseEnd = d.setTime(d.getTime() + 2 * 3600000);
          return twoHourFromCourseEnd > startingDate;
        }
      })
      .forEach((slot) => {
        const dayStartTs = moment(slot.dates.from)
          .clone()
          .startOf("day")
          .valueOf();
        if (dateSlots[dayStartTs]) {
          dateSlots[dayStartTs].availableQty += slot.availableQty;
          dateSlots[dayStartTs].disabled =
            dateSlots[dayStartTs].disabled &&
            !(slot.availableQty - this.bookableSlotNumber >= 0);
        } else {
          dateSlots[dayStartTs] = {
            availableQty: slot.availableQty,
            disabled: !(slot.availableQty - this.bookableSlotNumber >= 0),
          };
        }
      });

    const dates = Object.keys(dateSlots).map((tsStr) => ({
      model: {
        disabled: dateSlots[parseInt(tsStr)].disabled,
      },
      value: new Date(parseInt(tsStr)),
    }));
    return dates;
  }

  private setWholeFormTouched() {
    return this.fields.forEach((field) => {
      field.setTouched();
    });
  }
  private async validateWholeForm() {
    console.group("validateWholeForm");
    try {
      const selectedDate = this.selectedDate.value;
      const selectedTime = this.selectedTime.value;
      const selectedInstructor = this.selectedInstructor.value;
      const selectedService = this.selectedService.value;

      const validationPromises = this.fields
        .filter((field) => {
          if (field === this.attendees) {
            if (
              !selectedDate ||
              !selectedTime ||
              !selectedService ||
              !selectedInstructor
            ) {
              console.debug(
                "do not validate attendeesList if his dependencies don't satisfied"
              );
              return false;
            }
          } else {
            return true;
          }
        })
        .map((field) => field.requestValidation());

      await Promise.all(validationPromises);
      await when(() => !this.isFormValidating);
    } finally {
      console.groupEnd();
    }
  }
  private async tryToPay(data: ISavedInternalOrder) {
    try {
      const {
        attendees,
        eventId,
        locationId,
        promocode,
        giftcards,
        sid: oldSid,
        unitId,
        firstLessonStartDateTime,
        productType,
        bookingReservationId,
      } = data;

      console.log("Trying to book:", data);

      const clientId = this.userStore.user!.id;

      if (data.bookingId) {
        await CheckoutScreenModel.cancelUnpaidBooking(
          this.apiService,
          data.bookingId
        );
      }
      const isClass =
        !!this.selectedService.value && this.selectedService.value?.isClass;
      const bookedBundle =
        this.selectedService.value?.productType === ProductType.Bundle &&
        this.selectedService.value?.model.mustBeBookedOnPurchase;
      const serviceToBook = this.selectedService.value?.model.serviceToBook;
      const isNewbie = this.userStore.user?.isNewbie;

      const resp = await this.coreApiService.order(
        {
          attendees,
          clientId,
          eventId,
          firstLessonStartDateTime: new Date(firstLessonStartDateTime),
          isClass,
          klarnaOrderId: oldSid,
          layout: KlarnaLayout.Mobile,
          locationId: serviceToBook ? serviceToBook?.locationId : locationId,
          promocode,
          giftcards,
          unitId,
          productType,
          bookingReservationId,
          bookedBundle,
          serviceId: serviceToBook?.serviceId,
          mcLessonType:
            this.selectedWheeledVehicle.value === VehicleType.Mc && isNewbie
              ? MotorcycleLessonType.Lesson
              : this.selectedMotorcycleLessonType.value,
        },
        this.currentVehicleGearType
      );

      const sid = resp.id;
      const bookingId = resp.id;
      CheckoutScreenModel.saveInternalOrderInLocalStore({
        ...data,
        bookingId,
        sid,
        bookingReservationId,
      });
      if (sid) {
        this.nativeService.passSid(sid);
      }
      CheckoutScreenModel.saveKlarnaSnippetInLocalStore(resp.snippet);
    } catch (error) {
      let code: number = 1000;
      let errMsg: string = this.i18n.i18next.t(`errors.${code}`).toString();

      if (isAxiosError(error)) {
        // DELETEME after migration
        const errorData = error.response ? error.response.data : undefined;
        code = (errorData && errorData.data && errorData.data.code) || 1000;

        if (code === 1002) {
          const promotedCity = this.getLocationLabel(data.locationId);
          const currentUsersLocation =
            //this.userStore.user?.profile.currentLocationId;
            this.userStore.user?.settings.currentLocationId;
          const currentCity =
            !!currentUsersLocation &&
            this.getLocationLabel(currentUsersLocation);
          errMsg = this.i18n.i18next
            .t(`errors.${code}`, {
              currentCity: currentCity || "-",
              promotedCity,
            })
            .toString();
        } else {
          errMsg = this.i18n.i18next.t(`errors.${code}`).toString();
        }
      } else if (isCoreApiError(error)) {
        errMsg = error.error;
      }

      this.errorStatus.setMessage(errMsg);
      throw new Error(errMsg);
    } finally {
      bugsnagClient.leaveBreadcrumb(`checkout`);
      console.debug("order is ok");
    }
  }

  private async tryToOrder(data: ISavedInternalOrder) {
    try {
      const {
        attendees,
        eventId,
        locationId,
        promocode,
        giftcards,
        sid: oldSid,
        unitId,
        firstLessonStartDateTime,
        productType,
      } = data;

      const clientId = this.userStore.user!.id;

      if (data.bookingId) {
        await CheckoutScreenModel.cancelUnpaidBooking(
          this.apiService,
          data.bookingId
        );
      }
      const isClass =
        !!this.selectedService.value && this.selectedService.value?.isClass;
      const bookedBundle =
        this.selectedService.value?.productType === ProductType.Bundle &&
        this.selectedService.value?.model.mustBeBookedOnPurchase;
      const serviceToBook = this.selectedService.value?.model.serviceToBook;
      const isNewbie = this.userStore.user?.isNewbie;

      const resp = await this.coreApiService.order(
        {
          attendees,
          clientId,
          eventId,
          firstLessonStartDateTime: new Date(firstLessonStartDateTime),
          isClass,
          klarnaOrderId: oldSid,
          layout: KlarnaLayout.Mobile,
          locationId: serviceToBook ? serviceToBook.locationId : locationId,
          promocode,
          giftcards,
          unitId,
          productType,
          bookedBundle,
          serviceId: serviceToBook?.serviceId,
          mcLessonType:
            this.selectedWheeledVehicle.value === VehicleType.Mc && isNewbie
              ? MotorcycleLessonType.Lesson
              : this.selectedMotorcycleLessonType.value,
        },
        this.currentVehicleGearType
      );
      // if (!resp.success) {
      //   throw new Error(`Klarna confirmation failed: ${resp.status}`);
      // }
      const sid = resp.id;
      const bookingId = resp.id;
      CheckoutScreenModel.saveInternalOrderInLocalStore({
        ...data,
        bookingId,
        sid,
      });
      if (sid) {
        this.nativeService.passSid(sid);
      }
      CheckoutScreenModel.saveKlarnaSnippetInLocalStore(resp.snippet);
    } catch (error) {
      let code: number = 1000;
      let errMsg: string = this.i18n.i18next.t(`errors.${code}`).toString();

      if (isAxiosError(error)) {
        // DELETEME after migration
        const errorData = error.response ? error.response.data : undefined;
        code = (errorData && errorData.data && errorData.data.code) || 1000;

        if (code === 1002) {
          const promotedCity = this.getLocationLabel(data.locationId);
          const currentUsersLocation =
            // this.userStore.user?.profile.currentLocationId;
            this.userStore.user?.settings.currentLocationId;
          const currentCity =
            !!currentUsersLocation &&
            this.getLocationLabel(currentUsersLocation);
          errMsg = this.i18n.i18next
            .t(`errors.${code}`, {
              currentCity: currentCity || "-",
              promotedCity,
            })
            .toString();
        } else {
          errMsg = this.i18n.i18next.t(`errors.${code}`).toString();
        }
      } else if (isCoreApiError(error)) {
        errMsg = error.error;
      }

      this.errorStatus.setMessage(errMsg);
      throw new Error(errMsg);
    } finally {
      bugsnagClient.leaveBreadcrumb(`checkout`);
      console.debug("order is ok");
    }
  }

  private getLocationLabel(locationId: number) {
    return (
      this.settingsStore.locations.find(
        (option) => Number(option.id) === locationId
      )?.name.en || "—"
    );
  }

  private createAttendee(attendeeRaw: IFormStateAttendee) {
    const attendee = new Attendee();
    attendee.name.setValue(attendeeRaw.name, true);
    attendee.ssn.setValue(attendeeRaw.ssn, true);
    if (attendeeRaw.saved) {
      attendee.save();
    }
    return attendee;
  }

  @action
  private replaceAvailableInstructorSlots(val: IIntervalWithMeta[]): void {
    this.availableInstructorsSlots.replace(val);
    const selectedDate = this.selectedDate.value;
    const selectedTime = this.selectedTime.value;
    let selectedDateAvailable = false;
    if (this.booking?.id && !this.booking.isPaid) {
      const date = new Date(this.booking.startDate);
      this.selectDate(date);
      this.selectTime(date);
      return;
    }
    if (selectedDate) {
      selectedDateAvailable = val.some((interval) => {
        return (
          moment(interval.dates.from).startOf("day").valueOf() ===
          moment(selectedDate).startOf("day").valueOf()
        );
      });
    }
    if (!selectedDateAvailable) {
      this.selectDate(undefined);
    }
    let selectedTimeAvailable = false;
    if (selectedTime) {
      selectedTimeAvailable = val.some((interval) => {
        return moment(interval.dates.from).valueOf() === selectedTime.getTime();
      });
    }
    if (!selectedTimeAvailable) {
      this.selectTime(undefined);
    }
  }

  private async updateSelectableDates(
    datePickerDate: Date,
    selectedInstructorsIds?: number[],
    selectedService?: IServiceOption,
    selectedLocation?: ICityOption,
    selectedMotorcycleLessonType?: MotorcycleLessonType
  ) {
    try {
      this.datePickerLoading.startPending();
      if (selectedService && selectedLocation) {
        const from = moment(datePickerDate)
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
        const to = moment(datePickerDate)
          .clone()
          .endOf("month")
          .format("YYYY-MM-DD");

        const isClass = !!selectedService.value && selectedService.isClass;
        const isBundleMustBeBookedOnPurchase =
          !!selectedService.value &&
          selectedService.model.mustBeBookedOnPurchase;
        const isBundle =
          !!selectedService.value &&
          selectedService.model.productType === ProductType.Bundle;

        const isNewbie = this.userStore.user?.isNewbie;
        const clientId = this.userStore.user?.id;
        const isFulfilled = <T>(
          p: PromiseSettledResult<T>
        ): p is PromiseFulfilledResult<T> => p.status === "fulfilled";

        if (isClass || (isBundle && isBundleMustBeBookedOnPurchase)) {
          const slots = await this.coreApiService.getAvailableClassesSlots(
            from,
            to,
            isBundle
              ? selectedService.model.serviceToBook?.serviceId || 0
              : selectedService.value
          );

          const mappedSlots = slots.map((slot) => {
            return {
              availableQty: slot.qty,
              dates: { ...slot.interval },
              entityId: selectedService.value,
              isClass,
              seatsInSlot: slot.seats,
            };
          });

          this.replaceAvailableInstructorSlots(mappedSlots);
        } else {
          const hasMultipleGearType =
            this.currentVehicleGearType &&
            [GearType.McA2, GearType.McA].includes(
              this.currentVehicleGearType
            ) &&
            selectedService.model.meta?.key !== ServiceKey.DrivingTest;

          if (!!selectedInstructorsIds?.length) {
            const result = await Promise.allSettled(
              selectedInstructorsIds.map(async (instructorId) => {
                try {
                  const [slots, workload] = await Promise.all([
                    this.coreApiService.getAvailableSlots(
                      from,
                      to,
                      instructorId,
                      selectedService.value,
                      isClass,
                      hasMultipleGearType
                        ? MultipleGearType.McAMcA2
                        : this.currentVehicleGearType,
                      selectedLocation.value,
                      this.selectedWheeledVehicle.value === VehicleType.Mc &&
                        isNewbie
                        ? MotorcycleLessonType.Lesson
                        : selectedMotorcycleLessonType,
                      this.selectedWheeledVehicle.value === VehicleType.Mc ||
                        (!this.isCarPreSelected && this.isMCPreSelected)
                        ? clientId
                        : undefined
                    ),
                    await this.coreApiService.getInstructorWorkload(
                      from,
                      to,
                      instructorId
                    ),
                  ]);
                  return slots.map((slot) => ({
                    availableQty: slot.qty,
                    dates: { ...slot.interval },
                    entityId: instructorId,
                    isClass: false,
                    seatsInSlot: slot.seats,
                    workload,
                  }));
                } catch (e) {
                  console.error(e);
                  return [];
                }
              })
            );

            const dataDict: {
              [key: string]: IIntervalWithWorkload;
            } = {};
            const fulfilledValues = result
              .filter(isFulfilled)
              .map((p) => p.value);

            fulfilledValues
              .reduce((acc, val) => acc.concat(val), [])
              .forEach((el) => {
                const { dates } = el;
                const key = dates.from.getTime();

                if (dataDict.hasOwnProperty(key)) {
                  if (dataDict[key].workload >= el.workload) {
                    dataDict[key] = el;
                  }
                } else {
                  dataDict[key] = el;
                }
              });

            const availableTimeSlots: IIntervalWithMeta[] = Object.values(
              dataDict
            ).map((el) => {
              const { workload, ...res } = el;
              return res;
            });
            this.replaceAvailableInstructorSlots(availableTimeSlots);
          } else {
            this.replaceAvailableInstructorSlots([]);
          }
        }
      } else {
        this.replaceAvailableInstructorSlots([]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.datePickerLoading.stopPending();
    }
  }

  private async validatePromocode(data: {
    attendeesCount: number;
    day: Date | undefined;
    time: Date | undefined;
    promoCode: string | undefined;
    serviceId: number | undefined;
  }): Promise<string | undefined> {
    if (this.promoCodeValidationCancelTokenSource) {
      this.promoCodeValidationCancelTokenSource.cancel();
    }
    const user = this.userStore.user;
    if (!user) {
      throw new Error("User must be authenticated");
    }
    const { serviceId, day, time, attendeesCount, promoCode } = data;

    let error: undefined | string;
    let promoCodeData: undefined | ICorePromoCodeInfo;
    if (!promoCode) {
      error = undefined;
      promoCodeData = undefined;
    } else if (!serviceId) {
      console.warn("promocode filed dependencies not satisfied");
      error = "promocode filed dependencies not satisfied";
      promoCodeData = undefined;
    } else {
      const datetime =
        day && time ? this.datetime.fromDayAndTimePart(day, time) : moment();
      try {
        this.promoCodeValidationCancelTokenSource = Axios.CancelToken.source();
        const isValid = await this.coreApiService.checkPromocode(
          this.promoCodeValidationCancelTokenSource.token,
          promoCode,
          serviceId,
          datetime.toDate(),
          attendeesCount,
          user.email,
          user.id,
          this.selectedService.value?.model.productType
        );

        error = isValid
          ? undefined
          : this.i18n.i18next.t("order.errorPCIsNotValid").toString(); // TODO: error message
        if (isValid) {
          promoCodeData = await this.coreApiService.getPromoCodeData(
            this.promoCodeValidationCancelTokenSource.token,
            promoCode
          );
        }
      } catch (e) {
        console.log("error", error);

        const knownError = e as ErrorMessageCode;
        console.error("updatePromoCodeStatus", knownError.data.message);
        error = String(knownError.data.message);
      }
    }

    this.setPromoCodeData(promoCodeData);
    return error;
  }

  private async validateGiftCard(data: {
    attendeesCount: number;
    day: Date | undefined;
    time: Date | undefined;
    giftcard: string;
    serviceId: number | undefined;
  }): Promise<string | undefined> {
    const user = this.userStore.user;
    if (!user) {
      throw new Error("User must be authenticated");
    }

    const { serviceId, day, time, attendeesCount, giftcard } = data;

    /*if (giftcard.includes(" ")) {
      throw new Error("Gift card is not valid");
    }*/
    if (this.giftcards.find((card) => card === giftcard)) {
      throw new Error(
        this.i18n.i18next.t("order.errorAlreadyInUse").toString()
      );
    }

    let error: undefined | string;

    if (!giftcard) {
      error = undefined;
    } else if (!serviceId) {
      console.warn("giftcard filed dependencies not satisfied");
      error = "Giftcard filed dependencies not satisfied";
    } else {
      const datetime =
        day && time ? this.datetime.fromDayAndTimePart(day, time) : moment();
      try {
        const isValid = await this.coreApiService.checkGiftcard(
          giftcard as string,
          serviceId,
          datetime.toDate(),
          attendeesCount,
          user.email,
          user.id,
          this.selectedService.value?.model.productType
        );

        if (isValid === 0) {
          error = this.i18n.i18next.t("order.errorGCIsNotValid").toString();
        }

        if (isValid >= 0) {
          this.setGiftCardAmount(isValid);
        }
      } catch (e) {
        const knownError = e as ErrorMessageCode;
        console.error("updateCodeStatus", e);
        error = String(knownError.data.message);
      }
    }

    return error;
  }

  @action
  private setPromoCodeData(val: ICorePromoCodeInfo | undefined) {
    this.promoCodeData = val;
  }

  @action
  private setPromoCodeStatus(val: boolean | undefined) {
    this.promoCodeStatus = val;
  }
  private instructorToOption(instructor: IInstructorModel): IInstructorOption {
    return {
      label: instructor.name[this.i18n.currentLanguage],
      model: instructor,
      value: String(instructor.id),
    };
  }
}
